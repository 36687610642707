const MAIN_DATE_FORMAT = 'M/D/YYYY';
const MAIN_TIME_FORMAT = 'HHmm';

const DATE_FULL_DAY_FORMAT = 'MM/D/YYYY';

const DATE_FORMAT_API = 'YYYY-MM-DD';
const TIME_API_FORMAT = 'HH:mm';
const DATE_TIME_API_FORMAT = `${DATE_FORMAT_API} ${TIME_API_FORMAT}`;
const DATE_SHORT_TIME_WITH_TIMEZONE = `${DATE_FORMAT_API} HH:mmZ`;
const DATE_FULL_TIME_WITH_TIMEZONE = `${DATE_FORMAT_API} HH:mm:ssZ`;

const ISO_LOCAL_DATE_TIME = 'YYYY-MM-DD[T]HH:mm:ss';

const TEXT_DATE_FORMAT = 'ddd MMM DD, YYYY';
const SHORT_TEXT_DATE_FORMAT = 'ddd MMM DD';
const TIME_DATE_MILITARY = 'HHmm, MMM D';
const SHORT_TIME_WITH_TIMEZONE = 'HH:mmZ';

const FULL_YEAR_FORMAT = 'YYYY';
const TEXT_MONTH_DAY_FORMAT = 'ddd M/D';
const WEEK_MONTH_DAY_FORMAT = 'W (M/D)';

export {
    DATE_FORMAT_API,
    DATE_FULL_DAY_FORMAT,
    DATE_FULL_TIME_WITH_TIMEZONE,
    DATE_SHORT_TIME_WITH_TIMEZONE,
    DATE_TIME_API_FORMAT,
    FULL_YEAR_FORMAT,
    ISO_LOCAL_DATE_TIME,
    MAIN_DATE_FORMAT,
    MAIN_TIME_FORMAT,
    SHORT_TEXT_DATE_FORMAT,
    SHORT_TIME_WITH_TIMEZONE,
    TEXT_DATE_FORMAT,
    TEXT_MONTH_DAY_FORMAT,
    TIME_API_FORMAT,
    TIME_DATE_MILITARY,
    WEEK_MONTH_DAY_FORMAT,
};
